.btn-simple:hover{
    background-color: aliceblue!important;
}
.promt_title{
    font-size: 1.15rem;
}
.fit-content{
    width: fit-content!important;
}
.active{
    color: rgb(223, 56, 187)!important;
    font-weight: bold;
}
.pathline:hover{
    fill: black;
    cursor: pointer;
}
.pathimg:hover{
    filter: drop-shadow(13px 13px 1px black);
}
path{
    cursor: pointer;
}
.path4 {
    left: 9%;
    top: 0;
    width: 77%;
}
q{
    font-size: 18px;
}
.imageoutput{
    min-height: 60vh;
    width: 75%;
}
.generatebutton{
    border-left: 1px solid #2b3553 !important;
    background-color: rgb(144, 54, 138)!important;
}
.h-max{
    flex: 1 1 auto;
}

.preview_image{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 15%;
    width: 100%;
    height: 80vh;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}
#close_preview_image{
    position: absolute;
    right: 0;
    top: 15%;
    z-index: 5;
    color: white;
    background: black;
    padding: 5px;
    font-size: 25px;
    cursor: pointer;
}